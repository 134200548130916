<script setup>
import { useI18n } from 'vue-i18n'
const { $confirm, $notify } = useNuxtApp()
const { isLogged } = useAuth()

const { post, preview, card } = defineProps({ post: Object, preview: Boolean, card: Boolean })
const i18n = useI18n()

const date = computed( () => new Date(post?.date || Date.now()).toLocaleDateString(i18n.locale.value, {
    year: "numeric",
    month: "long",
    day: "numeric" }))

async function toggleVisibility () {
  try {
    await $fetch(`/api/post/detail/${post.id}`, { method: 'PATCH', body: { visible: !post.visible} })
    emit('toggleVisibility')
  } catch (e) {
    $notify("Error! " + e)
  }
}

const image = computed(() => post.image ? `/media/${post.image}` : '/media/fallbackImage.png')
const imageWebP = computed(() => post.image ? `/media/${post.image.replace(/.jpg$/, '.webp')}` : '/media/fallbackImage.png')

const emit = defineEmits(['remove', 'toggleVisibility', 'edit'])

async function remove () {
  const ret = await $confirm(`Are you sure yo want to remove post <u>${post.title}</u>?`)
  if (!ret) return
  try {
    await $fetch(`/api/post/${post.id}`, { method: 'DELETE', })
    $notify('Removed', 'success')
    emit('remove')
  } catch (e) {
    $notify("Error removing post: " + error.value.statusError)
  }
}

</script>
<template>
  <!-- :style="{ boxShadow: `inset 0px 5px 500px ${post?.source?.color ?? '#ddd'}`}" -->
  <!-- :style="{ border: `1px solid ${post?.source?.color ?? '#ddd'}`}" -->
  <article class="post" :class="{ hidden: post?.visible === false, card, [`source_${post?.source?.id}`]: true }" :style="{ border: `1px solid ${post?.source?.color || '#ccc'}`}">

    <!-- images has a webp version with a jpg fallback and an error fallback image -->
    <nuxt-link :to="post.URL" class="media" target="_blank">
      <v-img   aspect-ratio="4/3" height="155" :src="image" rounded="0" cover>
        <template v-slot:sources>
          <source :srcset="imageWebP" />
        </template>
        <template v-slot:error>
          <v-img src="/media/fallbackImage.png" />
        </template>
      </v-img>
    </nuxt-link>

    <section class="content space-between">
      <nuxt-link :href='post.URL' target="_blank" class='title font-weight-bold text-secondary'><span v-html='post.title' /></nuxt-link>
      <div class='summary text-caption text-medium-emphasis' v-text='post.summary' />
      <section class="tags flex-wrap mt-2">
        <div class='source-and-date font-weight-light text-caption footer' v-if="post.source">{{ date }} / <nuxt-link class='text-secondary' :to='`/s/${post.source.id}`'>{{post.source.name || post.source.link}}</nuxt-link></div>
        <div class='source-and-date font-weight-light text-caption footer' v-else>{{ date }}</div>
        <v-chip label :to='`/tag/${tag.id}`' v-for='tag in post?.tags?.slice(0, 5)' :key='tag.id' size='x-small' class='mr-1'>{{tag.name}}</v-chip>
      </section> 
      <v-toolbar v-if="!preview" class="actions" density="compact" size="small">
        <v-btn icon="mdi-text" variant="text" alt="delete" size="small" :to="`/p/${post.id}`" />

        <template v-if="isLogged">
          <v-btn icon="mdi-delete" color='red' variant="text" size="small" @click="remove" />
          <v-btn :icon="post?.visible ? 'mdi-eye-off' : 'mdi-eye'" color='orange' variant="text" size="small" @click="toggleVisibility" />
          <v-btn icon="mdi-pencil" color='info' variant="text" size="small" @click="emit('edit', post)" />
        </template>
      </v-toolbar>
    </section>
  </article>
</template>
<style>

.theme-Blob .post {
  flex-wrap: wrap;
  flex-direction: row;
}

.theme-Blob .post .actions {
  display: none;
  /* position: absolute;
  background: transparent;
  background-image: linear-gradient(to bottom, rgba(0,0,0, 1) , rgba(0,0,0,0));
  padding-bottom: 10px;
  top: -60px;
  left: 0px;
  width: 100%;
  transition: top .2s ease-in-out;   */
}

.theme-Grid .post {
  flex-direction: column;
}

.theme-Grid .post .actions {
  position: absolute;
  background: transparent;
  background-image: linear-gradient(to bottom, rgba(0,0,0, 1) , rgba(0,0,0,0));
  padding-bottom: 10px;
  top: -60px;
  left: 0px;
  width: 100%;
  transition: top .2s ease-in-out;
  /* opacity: 0.5; */
}

.theme-Grid .post:hover .actions {
  top: 0px;
}

.theme-Grid .post .content {
  justify-content: space-between;
  align-content: space-between;
  flex-grow: 1;
}

.theme-Blob .post .content {
  flex: 2 1 600px;
}

.post{
  
  position: relative;
  display: flex;
  
  background-color: #f3f3f3;
  border: 1px solid #ddd;

  /* max-height: 155px; */
  overflow: hidden;
  /* min-height: 200px; */

  width: 100%;
}
/* 
.card {
  flex-direction: column;
  width: 380px;
  flex-grow: 1;
} */

.post .navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 32px;
  overflow: hidden;
}

.post .navbar .tags {
  padding-top: 3px;
}

.post .media {
  /* width: 200px; */
  max-height: 155px;
  overflow: hidden;
  flex: 2 1 200px;
  background: none;
  height: 100%;
}

.media picture,
.media img {
  aspect-ratio: 1.7778;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.hidden {
  opacity: 0.4;
}

.post .content {
  overflow: hidden;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* max-height: 155px; */
}

.content .summary {
  max-height: 60px;
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
}

.content a {
  color: rgba(var(--v-theme-primary));
}

</style>